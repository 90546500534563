import React from "react";

import BongHoang from "../images/bonghoang-profile.jpg";
import ProfilePicture from "./ProfilePicture";

const Bio = () => {
  return (
    <div className="bio">
      <ProfilePicture src={BongHoang}/>
      <div>
        <h3>About</h3>
        <p>
          I am a Graduate of Full Sail University with a Bachelor's of Science in Game Design. I started on my path with this
          degree seeking to contribute to a growing industry. 
        </p>
        <p>
          During my time at Full Sail, I have obtained the foundational
          skills to design and develop games, both analog and digital. These skills have developed my experience collaborating
          within teams, debugging, facilitating playtests for QA / feedback, and writing code in C#.
        </p>
        <h3>Background</h3>
        <p>
          Before Full Sail University, I have worked in various sales and call center roles. During this time, I have 
          acquired skills related to management, customer relations, team development, professional communication, 
          and product knowledge.
        </p>
        <p>
          Additionally, I studied in traditional martial arts for 15 years and dance for 7 years. In martial arts, I 
          learned to continue improving myself no matter where you are in life. Dance taught me the importance of expression
          with movement and the skills it requires to portray a story without saying a single word.
        </p>
      </div>
    </div>
  )
}

export default Bio;