import React from 'react';
import { NavLink } from 'react-router-dom';

const Header = (props) => {
  const { 
    title, 
    subtitle
  } = props;

  return (
    <header className="portfolio-header">
      <div>
        <h1>
          { title }
        </h1>
        <p>
          { subtitle }
        </p>
      </div>
      <div className="links">
        <NavLink exact to="/"><span>Home</span></NavLink>
        <NavLink to="/inner-worlds"><span>Inner Worlds</span></NavLink>
        <NavLink to="/norman-the-dragicorn"><span>Norman the Dragicorn</span></NavLink>
        <NavLink to="/projects"><span>Other Projects</span></NavLink>
        <NavLink to="/contact"><span>Contact</span></NavLink>
      </div>
    </header>
  )
}

export default Header;