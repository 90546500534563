import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

// import InitialTransition from "./components/InitialTransition";
import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";

import "hover.css";
import "./App.css";

function App() {
  const [isFadedOut, setFadedOut] = useState(true);

  const fadeOutToIn = () => {
    setTimeout(() => {
      setFadedOut(false);
    }, 400);
  }

  useEffect(() => {
    fadeOutToIn();
  })

  const AppClassNames = isFadedOut ? "App fade-out" : "App";
  
  return (
    <Router>
      <div className={AppClassNames}>
      {/* <div className="App"> */}
        {/* <InitialTransition /> */}
        <div id="canvas">
          <Header title={"Bong Hoang"} subtitle={"Game Designer"} />
          <Content />
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
