import React, { useLayoutEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";

import Home from "./Home";
import InnerWorlds from "./InnerWorlds";
import Norman from "./Norman";
import Contact from "./Contact";
import Preview from "./Preview";

import projects from "../data/projects.json";

const Content = () => {
  const [width] = useWindowSize();

  const iframeWidth = width <= 620 ? 300 : 560;
  const iframeHeight = width <= 620 ? 200 : 315;

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  
  function generateProjectCards(projects) {
    return projects.map(createProjectCard);
  }

  function createProjectCard(project, index) {
    return (
      <div className="project" key={index}>
        <div className="card hvr-shadow">
          <h2>{project.title}</h2>
          <div className="info">
            <p><strong>Role</strong>: {project.role}</p>
            <p><strong>Tools</strong>: {project.tools}</p>
          </div>
          <iframe width={iframeWidth} height={iframeHeight} src={`https://www.youtube.com/embed/${project.id}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          <p>{project.description}</p>
        </div>
      </div>
    )
  }

  return (
    <div className="content">
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/inner-worlds">
          <InnerWorlds />
        </Route>
        <Route exact path="/norman-the-dragicorn">
          <Norman />
        </Route>
        <Route path="/projects">
          { generateProjectCards(projects) }
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/images/:fileName">
          <Preview />
        </Route>
      </Switch>
    </div>
  );
}

export default Content;
