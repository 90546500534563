import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import images from "./utils/images";

import LeftArrow from "../images/logos/left-arrow.png"
import RightArrow from "../images/logos/right-arrow.png"

const Preview = (props) => {
  let history = useHistory();

  const image = images["all"].find(image => image.path === history.location.pathname);
  // const { imageIndex, imageSet } = history;

  // const [counter, setCounter] = useState(imageIndex);

  // function incrementCounter() {
  //   counter++;
  //   if (counter === imageSet.length) {
  //     counter = 0;
  //   }
  //   setCounter(counter)    
  // }

  // function decrementCounter() {
  //   counter--;
  //   if (counter === -1) {
  //     counter = imageSet.length - 1;
  //   }
  //   setCounter(counter)   
  // }

  // const image = imageSet[counter];
  // console.log("The damn index", counter);



  const { src, title } = image;

  return (
    <div className="preview">
      <div className="top-controls">
        <span onClick={() => { history.goBack() }}>X</span>
      </div>
      <div className="image">
        <img src={ src } alt={ title }/>
      </div>
      <h4>{ title }</h4>
      {/* <div className="bottom-controls">
        <img src={ LeftArrow } onClick={() => {  }} />
        <img src={ RightArrow } onClick={() => {  }} />
      </div> */}
    </div>
  )
}

export default Preview;
