import React, { useState } from "react";
import { Link } from "react-router-dom";

function Thumbnail(props) {
  const { imageOptions, imageSet, imageIndex } = props;
  const { src, title, path } = imageOptions;

  return (
    <div className="thumb">
      <Link to={{ pathname: path, options: imageOptions, imageIndex: imageIndex, imageSet: imageSet }}> 
        <img src={src} alt={title} />
      </Link>
      <div className="title">
        { title }
      </div>
    </div>
  )
}

export default Thumbnail;