import React, { useState, useLayoutEffect } from 'react';
import itchioBadge from "../images/badges/itch.io-badge-black.svg"

import LightBox from "./LightBox";
import images from "./utils/images";

const InnerWorlds = () => {
  const [width] = useWindowSize();

  const iframeWidth = width <= 620 ? 300 : 560;
  const iframeHeight = width <= 620 ? 200 : 315;

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  return (
    <div className="project">
      <div className="card hvr-shadow">
        <h1>Inner Worlds</h1>
        <div className="info">
          <p><strong>Role</strong>: Game Designer, Level Designer</p>
          <p><strong>Tools</strong>: Unity 3D, Visual Studio</p>
        </div>
        <iframe width={iframeWidth} height={iframeHeight} src={`https://www.youtube.com/embed/iOCuQPhkTEc`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <p>On <strong>Inner Worlds</strong>, I was both a Game Designer, and Level Designer. My duties included:</p>
        <p>Scripting game mechanics such as</p>
        <ul className="list">
          <li>Command UI</li>
          <li>Magic Cast action</li>
          <li>Environment triggers</li>
          <li>Level blockout</li>
          <li>Version control via perforce</li>
          <li>Level Design for The City Level and the Void Level</li>
          <li>Health and Mana implementation for player Avatar</li>
          <li>Platforming</li>
          <li>Boss mechanics</li>
        </ul>
        <p>I worked with my team to complete this project for a beta release within 4 months. This was our first release and is available for download on <a className="itch-link" href="https://bongvhoang.itch.io/inner-worlds" target="_blank" rel="noopener noreferrer">Itch.io</a>. I am grateful to have worked with talented individuals to create Inner Worlds.</p>
        <h2>Level Designs</h2>
        <LightBox title={`The City`} imageSet={images.InnerWorldsCityImageSet} />
        <LightBox title={`The Void`} imageSet={images.InnerWorldsVoidImageSet} />
        <p><strong>Overview</strong>: Play as Diz and travel different imaginary worlds with your friends to discover treasures, allies, and protect each world from the nightmares that threaten to devour your imagination. Fight off the Nightmares alongside your companions, cast spells, and work together as a team to save each imaginary world from the nightmares that plague them.   </p>
        <div className="download-link">
          <p>Download the game here</p>
          <a className="hvr-shadow" href="https://bongvhoang.itch.io/inner-worlds" target="_blank" rel="noopener noreferrer"><img src={itchioBadge} alt="itch.io Download Badge" /></a>
        </div>
      </div>
    </div>
  )
}

export default InnerWorlds;
