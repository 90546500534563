import React from "react";
import Thumbnail from "./Thumbnail";

const LightBox = (props) => {
  const {
    title,
    imageSet
  } = props;

  function generateThumbnails(images) {
    return images.map((image, index) => {
      return (
        <Thumbnail key={index} imageOptions={image} imageSet={images} imageIndex={index} />
      )
    });
  }

  return (
    <>
      <h3>{ title }</h3>
      <div className="level-designs">
        { generateThumbnails(imageSet) }
      </div>
    </>
  )
}

export default LightBox;
