import React from "react";

import Doc from "../images/logos/doc.png";
import PDF from "../images/logos/pdf.png";

const ProfilePicture = (image) => {
  const { src, title } = image

  return (
    <div className="profile-picture">
      <img src={src} alt={title}></img> 
      <div className="resume">
        <a href="/files/Bong_Hoang_Resume.docx" download><img className="hvr-shadow" src={Doc} alt="Resume - Word Document" /></a>
        <a href="/files/Bong_Hoang_Resume.pdf" download><img className="hvr-shadow" src={PDF} alt="Resume - PDF" /></a>
      </div>
    </div>
  )
}

export default ProfilePicture;