import React from "react";
import EmailLogo from "../images/logos/email.png";
import LinkedInLogo from "../images/logos/linkedin.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <h5>Copyright &copy; 2021 Bong Hoang</h5>
      </div>
      <div className="social">
        <div>
          <a target="_blank" href="mailto:bongvhoang@gmail.com" rel="noopener noreferrer"><img src={EmailLogo} alt={EmailLogo.title} /></a>
        </div>
        <div className="linkedin">
          <a target="_blank" href="https://www.linkedin.com/in/bong-hoang-3052408a/" rel="noopener noreferrer"><img src={LinkedInLogo} alt={LinkedInLogo.title} /></a>
        </div>
      </div>
    </div>
  )
}

export default Footer;