import React from "react";
import { NavLink } from 'react-router-dom';

import InnerWorldsLevelDesigns from "../images/level-designs.gif";
import InnerWorldsLogo from "../images/inner-worlds-logo.png";
import NormanTheDragicornLogo from "../images/norman-the-dragicorn-logo.png";

// TODO: Optimize this
const Home = () => {
  return (
    <div className="project">
      <div className="card hvr-shadow">
        <div className="highlight-image">
          <img src={InnerWorldsLevelDesigns} alt="Level Designs" />
        </div>
        <p>Hello I am a Game Designer with 2 years of experience. My goal is to create video games that are fun and enjoyable for the player.</p>
        <p>I have published one titled game, <span className="title-project"><NavLink to="/inner-worlds">Inner Worlds</NavLink></span>.</p>
        <p><strong>What Can I Do?</strong></p>
        <p>Currently, I focus my work on game and level design. I have created UIs for games to display actions, health and magic bars for the player,enemies and environment. I have implemented spell casting with a state machine. I have adapted to creating first-person and third-person games alike. I have worked with teams to meet deadlines and help lead the project to completion. I am currently working on a personal project titled Overthinking.</p>
        {/* TODO */}
        <div className="highlighted-projects">
          <NavLink to="/inner-worlds"><img className="hvr-shadow" src={InnerWorldsLogo} alt="Inner Worlds Logo"></img></NavLink>
          <NavLink to="/norman-the-dragicorn"><img className="hvr-shadow" src={NormanTheDragicornLogo} alt="Norman the Dragicorn Logo"></img></NavLink>
        </div>
      </div>
    </div>
    
  )
}

export default Home;