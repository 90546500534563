import React, { useState, useLayoutEffect } from 'react';

import LightBox from "./LightBox";
import images from "./utils/images";

const Norman = () => {
  const [width] = useWindowSize();

  const iframeWidth = width <= 620 ? 300 : 560;
  const iframeHeight = width <= 620 ? 200 : 315;

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  return (
    <div className="project">
      <div className="card hvr-shadow">
        <h1>Norman the Dragicorn</h1>
        <div className="info">
          <p><strong>Role</strong>: Game Designer</p>
          <p><strong>Tools</strong>: Unity 3D, Visual Studio</p>
        </div>
        <iframe width={iframeWidth} height={iframeHeight} src={`https://www.youtube.com/embed/MQ8aGoP0-Z4`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <p>On <strong>Norman the Dragicorn</strong>, I was a Game Designer. My duties included:</p>
        <ul className="list">
          <li>Movement, Jump, and Glide Mechanic</li>
          <li>Player and level Health Mechanic</li>
          <li>Player and enemies model via <strong>ProBuilder</strong></li>
          <li>Jump IPM</li>
        </ul>
        <p>I worked on this project with my team for a month and completed it to a pre alpha stage. Norman the Dragicorn is a Fantasy/Action game where Norman protects the forest from evil hobgoblins and slime monsters.</p>
        <LightBox title="Model Blockout created by ProBuilder" imageSet={images.NormanModelsImageSet} />
      </div>
    </div>
  )
}

export default Norman;
