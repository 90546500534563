import React, { useState } from "react";
import { useForm } from "react-hook-form";

import Bio from "./Bio";

const Contact = () => {
  const [sendMailSuccess, setMailSuccess] = useState(false);
  const [sendMailFailed, setMailFailed] = useState(false);
  const { handleSubmit, register, errors } = useForm();

  const sendEmail = async (url = '', data = {}) => {
    const response = await fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return await response.json(); // parses JSON response into native JavaScript objects
  }

  const onSubmit = (data, e) => {
    sendEmail('/api/sendmail', data)
      .then((response) => {
        e.target.reset();
        setMailSuccess(true);
      })
      .catch((response) => setMailFailed(true));
  }

  return (
    <div className="project">
      <div className="card">
        <Bio />
        <div className="contact">
          <h2>Contact</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <label>First Name &#42;</label>
            <input name="firstName" ref={register({ required: true, maxLength: 20 })} /> 
            {errors.firstName && <span>Please enter your first name</span>}
            <label>Last Name &#42;</label>
            <input name="lastName" ref={register({ required: true, pattern: /^[A-Za-z]+$/i })} />
            {errors.lastName && <span>Please enter your last name</span>}
            <label>Email &#42;</label>
            <input name="email" ref={register({ pattern: /^[a-zA-Z0-9_\.\+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+$/i, required: true })} />
            {errors.email && <span>Please provide a valid email</span>}
            <label>Subject &#42;</label>
            <input name="subject" ref={register({ required: true })} />
            {errors.subject && <span>Please provide a subject</span>}
            <label>Message &#42;</label>
            <textarea name="message" defaultValue="Bong Hoang, &#13;&#13;[YOUR MESSAGE HERE]" ref={register({ required: true, maxLength: 250 })} />
            {errors.message && <span>Please tell me what you'd like!</span>}
            <input type="submit" />
          </form>
          { sendMailSuccess && <span className="mail-success">Email sent successfully! Please allow up to 24 hours for a reply!</span>}
        </div>
      </div>      
    </div>
  )
}

export default Contact;
