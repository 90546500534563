import CityDesign1 from "../../images/inner-worlds/city-design-1.png"
import CityDesign2 from "../../images/inner-worlds/city-design-2.png"
import CityDesign3 from "../../images/inner-worlds/city-design-3.png"
import CityDesign4 from "../../images/inner-worlds/city-design-4.png"
import VoidDesign1 from "../../images/inner-worlds/void-design-1.png"
import VoidDesign2 from "../../images/inner-worlds/void-design-2.png"
import VoidDesign3 from "../../images/inner-worlds/void-design-3.png"
import VoidDesign4 from "../../images/inner-worlds/void-design-4.png"
import NormanModel1 from "../../images/norman-the-dragicorn/model-blockout-1.png"
import NormanModel2 from "../../images/norman-the-dragicorn/model-blockout-2.png"
import NormanModel3 from "../../images/norman-the-dragicorn/model-blockout-3.png"
import NormanModel4 from "../../images/norman-the-dragicorn/model-blockout-4.png"

const images = {
  all: [
    { path: "/images/inner-worlds/city-design-1" , src: CityDesign1, title: "City Design - 1"}, 
    { path: "/images/inner-worlds/city-design-2" , src: CityDesign2, title: "City Design - 2"}, 
    { path: "/images/inner-worlds/city-design-3" , src: CityDesign3, title: "City Design - 3"}, 
    { path: "/images/inner-worlds/city-design-4" , src: CityDesign4, title: "City Design - 4"},
    { path: "/images/inner-worlds/void-design-1" , src: VoidDesign1, title: "Void Design - 1"}, 
    { path: "/images/inner-worlds/void-design-2" , src: VoidDesign2, title: "Void Design - 2"}, 
    { path: "/images/inner-worlds/void-design-3" , src: VoidDesign3, title: "Void Design - 3"}, 
    { path: "/images/inner-worlds/void-design-4" , src: VoidDesign4, title: "Void Design - 4"},
    { path: "/images/norman-the-dragicorn/model-blockout-1" , src: NormanModel1, title: "Model Blockout - 1"}, 
    { path: "/images/norman-the-dragicorn/model-blockout-2" , src: NormanModel2, title: "Model Blockout - 2"}, 
    { path: "/images/norman-the-dragicorn/model-blockout-3" , src: NormanModel3, title: "Model Blockout - 3"}, 
    { path: "/images/norman-the-dragicorn/model-blockout-4" , src: NormanModel4, title: "Model Blockout - 4"}
  ],
  InnerWorldsCityImageSet: [
    { path: "/images/inner-worlds/city-design-1" , src: CityDesign1, title: "City Design - 1"}, 
    { path: "/images/inner-worlds/city-design-2" , src: CityDesign2, title: "City Design - 2"}, 
    { path: "/images/inner-worlds/city-design-3" , src: CityDesign3, title: "City Design - 3"}, 
    { path: "/images/inner-worlds/city-design-4" , src: CityDesign4, title: "City Design - 4"}
  ],
  InnerWorldsVoidImageSet: [
    { path: "/images/inner-worlds/void-design-1" , src: VoidDesign1, title: "Void Design - 1"}, 
    { path: "/images/inner-worlds/void-design-2" , src: VoidDesign2, title: "Void Design - 2"}, 
    { path: "/images/inner-worlds/void-design-3" , src: VoidDesign3, title: "Void Design - 3"}, 
    { path: "/images/inner-worlds/void-design-4" , src: VoidDesign4, title: "Void Design - 4"}
  ],
  NormanModelsImageSet: [
    { path: "/images/norman-the-dragicorn/model-blockout-1" , src: NormanModel1, title: "Model Blockout - 1"}, 
    { path: "/images/norman-the-dragicorn/model-blockout-2" , src: NormanModel2, title: "Model Blockout - 2"}, 
    { path: "/images/norman-the-dragicorn/model-blockout-3" , src: NormanModel3, title: "Model Blockout - 3"}, 
    { path: "/images/norman-the-dragicorn/model-blockout-4" , src: NormanModel4, title: "Model Blockout - 4"}
  ]
}

export default images;
